




import { FormTruckingInvoice } from "@/components/TruckingAccountReceivable";
import Vue from "vue";

export default Vue.extend({
  name: "InvoiceCreatePage",
  components: {
    FormTruckingInvoice,
  },
});
